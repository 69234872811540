.tops ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.750);
}

.tops li {
  display: block;
  float: right;
  font-size: 20px;
  display: block;
  color: white;
  text-align: center;
  padding: 28px 32px;
  text-decoration: none;
}

.tops li:hover {
  color: rgba(0, 0, 0, 0.750);
  background-color: white;

}

.img1 {
  text-align: center;
  padding: center;
}


@media (max-width: 768px) {
  .tops li {
    font-size: 80%;
    padding: 12px 16px;
  }
}

